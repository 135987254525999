import axios from './axiosInit';

import store from '@/store';
import { DeviceUUID } from 'device-uuid';

import config from '../../config';

const utf8 = require('utf8');
const sha1 = require('sha1');

const getIdSeed = function ()
{
    let deviceUId = new DeviceUUID().get();
    let data = utf8.encode(deviceUId);
    let hashValue = sha1(data);
    return hashValue;
    // return '2c399a5fdca57635f0e867793dfae863c986b110';
}

// Other
const otherChannelKey = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjAxMzlCRDY5QkNBODZBRDkxNjdCNDEyRTI3RkFGQTZFIiwidHlwIjo';
// Login
// const loginChannelKey = '9VCBF3utjOva8h_ArblTB2VoHtmStosoL8UBQWJnCA9NCXub-LeOekUpk6ReZhKlp_m1mL9yodJoxMLcGP9';
const loginChannelKey = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjAxMzlCRDY5QkNBODZBRDkxNjdCNDEyRTI3RkFGQTZFIiwidHlwIjoiYXQrand0In0' // FOR CONS Channel

const getToken = async (type, userName) =>
{
    try
    {
        let tokenModel = {
            secretKey: '635E995196B725D9917582D85D5625CDB8DED24EEE1D96C0E3E496745A02D7CA',
            channelKey: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjAxMzlCRDY5QkNBODZBRDkxNjdCNDEyRTI3RkFGQTZFIiwidHlwIjo',
            channelId: 'CONS',
            identitySeed: getIdSeed(),
            userName: userName,
            device: 'cons',
            version: '1.0.1',
            type: type,
            encryptionAlg: 'A256GCM',
            keyWrapAlgorithm: 'A256KW',
        }

        if (type == 'login')
        {
            tokenModel.channelKey = loginChannelKey;
        }

        const result = await axios.post('hpsbridge/EncryptionApp/token', tokenModel)
        return result
    } catch (e)
    {
        // console.log(e)
    }
}

const refreshToken = async () =>
{
    try
    {
        let userName = store.get('requestKeys/userName');
        return getToken('login', userName).then(async refreshRes =>
        {
            let payload = {
                requestInfo: {
                    channelKey: refreshRes.data.results.channelKey,
                    identityKey: refreshRes.data.results.identityKey,
                    requestDate: refreshRes.data.results.requestDate,
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    channelId: store.get('requestKeys/channelId'),
                    userName: userName,
                    // identitySeed: sharedServices.getIdSeed()
                },
                refreshToken: localStorage.getItem('refreshToken')
            };

            const auth = {
                headers: {
                    Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
                }
            }

            const result = await axios.post('hpsbridge/se/user/token/refresh', payload, auth)
            return result
        })
    } catch (e)
    {
        // console.log(e)
    }
}

const refreshToken2 = async (type, userName) =>
{
    try
    {
        let payload = {
            requestInfo: {
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                requestDate: store.get('requestKeys/requestDate'),
                channelKey: loginChannelKey,
                // channelKey: store.get('requestKeys/channelKey'),
                identityKey: store.get('requestKeys/identityKey'),
                channelId: store.get('requestKeys/channelId'),
                // identitySeed: store.get('requestKeys/identitySeed'),
            },
            refreshToken: localStorage.getItem('refreshToken'),
        }

        const result = await axios.post('hpsbridge/se/user/token/refresh', payload)
        // console.log(result);
        return result
    } catch (e)
    {
        // console.log(e)
    }
}

const getCaptcha = async () =>
{
    try
    {
        store.dispatch('loader/show');

        const payload = {
            requestInfo: {
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                requestDate: store.get('requestKeys/requestDate'),
                channelKey: store.get('requestKeys/channelKey'),
                identityKey: store.get('requestKeys/identityKey'),
                channelId: store.get('requestKeys/channelId'),
                identitySeed: store.get('requestKeys/identitySeed'),
                userName: null,
            },
            serviceName: 'HpsBridge'
        };

        const auth = {
            headers: {
                Authorization: 'Bearer ' + store.get('requestKeys/enrollmentToken')
            }
        }

        const result = await axios.post('hpsbridge/se/enrolment/requestCaptcha', payload, auth)
        store.dispatch('loader/hide');

        return result
    } catch (e)
    {
        // console.log(e)
    }
}

const getUserPrefers = async () =>
{
    try
    {
        let information = store.get('card/details');
        let clientDetail = store.get('accounts/clientDetail');

        let data = {
            requestInfo: {
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                requestDate: store.get('requestKeys/requestDate'),
                channelKey: store.get('requestKeys/channelKey'),
                identityKey: store.get('requestKeys/identityKey'),
                channelId: store.get('requestKeys/channelId'),
                identitySeed: store.get('requestKeys/identitySeed'),
                userName: store.get('requestKeys/userName'),
            },
            fileNumber: information.primeCardSerno,
            clientCode: store.get('requestKeys/ClientCode'),
            legalId:  clientDetail.clientDemog.legalId,
        }
        try
        {
            const auth = {
                headers: {
                    Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
                }
            }
            store.dispatch('loader/show');
            const result = await axios.post('hpsbridge/digitaltps/User/GetUserPreference', data, auth)
            store.dispatch('loader/hide');
            return result
        } catch (e)
        {
            // console.log(e)
        }
    } catch (e)
    {
        // console.log(e)
    }
}

const addUserPrefers = async (data) =>
{
    try
    {
        let information = store.get('card/details');
        let clientDetail = store.get('accounts/clientDetail');

        let payload = {
            requestInfo: {
                requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                requestDate: store.get('requestKeys/requestDate'),
                channelKey: store.get('requestKeys/channelKey'),
                identityKey: store.get('requestKeys/identityKey'),
                channelId: store.get('requestKeys/channelId'),
                identitySeed: store.get('requestKeys/identitySeed'),
                userName: store.get('requestKeys/userName'),
            },
            fileNumber: information.primeCardSerno,
            clientCode: store.get('requestKeys/ClientCode'),
            legalId: clientDetail.clientDemog.legalId,
            userLanguage: data.userLanguage,
            promotionalSMS: data.promotionalSMS,
            promotionalEmail: data.promotionalEmail,
            promotionalCalls: data.promotionalCalls
        }
        try
        {
            const auth = {
                headers: {
                    Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
                }
            }
            store.dispatch('loader/show');
            const result = await axios.post('hpsbridge/digitaltps/User/AddUserPreference', payload, auth)
            store.dispatch('loader/hide');
            return result
        } catch (e)
        {
            console.log(e)
        }
    } catch (e)
    {
        console.log(e)
    }
}

const handelError = (title, message, icon) =>
{

}


export default {
    getToken,
    getCaptcha,
    getIdSeed,
    refreshToken,
    handelError,
    loginChannelKey,
    getUserPrefers,
    addUserPrefers
}
