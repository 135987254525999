export const loader = {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {
        show({ commit })
        {
            commit("show");
        },
        hide({ commit })
        {
            commit("hide");
        }
    },
    mutations: {
        show(state)
        {
            state.loading = true;
            // console.log(state)
        },
        hide(state)
        {
            state.loading = false;
            // console.log(state)
        }
    }
};