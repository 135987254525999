<template>
    <!-- <div @mousemove="mouseMove"> -->
    <component :is="resolveLayoutVariant"
               :class="`skin-variant--${appSkinVariant}`">
        <!-- <transition :name="appRouteTransition"
                    mode="out-in"
                    appear> -->
        <div>
            <loader :visible="loading"></loader>
            <router-view></router-view>
        </div>

        <!-- </transition> -->
    </component>
    <!-- </div> -->
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import useDynamicVh from '@core/utils/useDynamicVh'

import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';

import sharedServices from './services/shared-services';
import store from './store'
import { mapState } from 'vuex';
import loader from './views/loader.vue'

import Encrypted from '@/layouts/variants/encrypted/Encrypted.vue'

import { getVuetify } from '@core/utils'

// import router from './router';
// Dynamic vh

export default {
    components: {
        LayoutContentVerticalNav,
        LayoutBlank,
        loader,
        Encrypted
    },
    computed: {
        ...mapState("loader", ["loading"])
    },
    watch: {
        $route(to, from)
        {
            window.scrollTo(0, 0);
        }
    },
    setup()
    {
        let identitySeed = sharedServices.getIdSeed();
        store.set('requestKeys/identitySeed', identitySeed);

        const { route } = useRouter()
        const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

        const $vuetify = getVuetify()

        const { handleBreakpointLayoutSwitch } = useLayout()
        handleBreakpointLayoutSwitch()

        const resolveLayoutVariant = computed(() =>
        {
            let lang = localStorage.getItem('language');
            if (lang == 'en')
            {
                $vuetify.rtl = false;
            }
            else if (lang == 'ar')
            {
                $vuetify.rtl = true;
            }
            let encrypted = localStorage.getItem('Encrypted');
            if (encrypted == 'rEmuclV0sTLJppFz2QZj9mAE5BrKNHnA e2yRvcDbKrDg8U4939i2W6eTXq5NlHpP J0n906tplzVheKwHHeL54xnC0qLF4fr4')
            {
            if (route.value.meta.layout === 'blank') return 'layout-blank'
            if (route.value.meta.layout === 'content') 
            {
                return `layout-content-${appContentLayoutNav.value}-nav`
            }
            }
            else
            {
                return 'encrypted'
            }

            return null
        })

        useDynamicVh()

        return {
            resolveLayoutVariant,
            appSkinVariant,
            appRouteTransition,
        }
    },
}
</script>
