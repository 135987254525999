<template>
    <div class="auth-wrapper auth-v1"
         style="padding-bottom:0px;padding-top:0px;">
        <div class="auth-inner main-container-width">

            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-form ref="loginForm"
                            class="multi-col-validation"
                            @submit.prevent="saveUserData"
                            lazy-validation
                            v-model="valid">
                        <v-row>
                            <v-col cols="12"
                                   lg="3"
                                   md="3"
                                   sm="12">
                            </v-col>
                            <v-col cols="12"
                                   lg="6"
                                   md="6"
                                   sm="12">
                                <div>
                                    <br><br>
                                    <p class="arabic-font text-lg-2xl text-xl font-weight-semibold text-black pt-5"
                                       style="margin-bottom: 0;">
                                        Enter your Demo Password to Login
                                    </p>
                                    <br>
                                    <p class="arabic-font font-weight-semibold mb-4 mt-4"
                                       style="font-size: 13px;color: red">
                                        {{ message }}
                                    </p>
                                    <div style="margin: auto;">
                                        <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-4"
                                           style="font-size: 13px;">
                                            Enter First Demo Password
                                        </p>
                                        <br>
                                        <v-text-field v-model="first"
                                                      outlined
                                                      type="text"
                                                      :rules="genericRules"
                                                      hide-details="auto"
                                                      class="arabic-font mb-2">
                                        </v-text-field>
                                        <br>
                                        <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-4"
                                           style="font-size: 13px;">
                                            Enter Second Demo Password
                                        </p>
                                        <br>
                                        <v-text-field v-model="second"
                                                      outlined
                                                      :rules="genericRules"
                                                      hide-details="auto"
                                                      class="arabic-font mb-2">
                                        </v-text-field>
                                        <br>
                                        <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-4"
                                           style="font-size: 13px;">
                                            Enter Third Demo Password
                                        </p>
                                        <br>
                                        <v-text-field v-model="third"
                                                      outlined
                                                      :rules="genericRules"
                                                      hide-details="auto"
                                                      class="mb-2 arabic-font">
                                        </v-text-field>
                                        <br>
                                        <v-btn block
                                               color="primary"
                                               type="submit"
                                               x-large
                                               class="arabic-font mt-6 py-3">
                                            <span style="color: black">Visit Demo</span>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12"
                                   lg="3"
                                   md="3"
                                   sm="12">
                            </v-col>
                        </v-row>
                    </v-form>

                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        valid: false,
        first: '',
        second: '',
        third: '',
        message: '',
        genericRules: [
            v => !!v || 'Demo Password is required'
        ]
    }),

    methods: {
        saveUserData()
        {
            this.$refs.loginForm.validate();
            if (this.$refs.loginForm.validate())
            {
                if (this.first == 'rEmuclV0sTLJppFz2QZj9mAE5BrKNHnA')
                {
                    if (this.second == 'e2yRvcDbKrDg8U4939i2W6eTXq5NlHpP')
                    {
                        if (this.third == 'J0n906tplzVheKwHHeL54xnC0qLF4fr4')
                        {
                            localStorage.setItem('Encrypted', this.first + ' ' + this.second + ' ' + this.third);
                            window.location.reload();
                        }
                        else
                        {
                            this.message = 'Incorrect Third Password'
                        }
                    }
                    else
                    {
                        this.message = 'Incorrect Second Password'
                    }
                }
                else
                {
                    this.message = 'Incorrect First Password'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }
}
</style>
