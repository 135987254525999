<template>
    <div @mousemove="mouseMove"
         @mousedown="mouseMove">
        <layout-content-vertical-nav :nav-menu-items="navMenuItems">
            <slot></slot>

            <!-- Slot: Navbar { isVerticalNavMenuActive, toggleVerticalNavMenuActive }-->
            <template #navbar="">
                <v-card tile
                        class="header-top-bar px-5">
                    <div class="navbar-content-container"
                         :class="{ 'expanded-search': shallShowFullSearch }">
                        <!-- Left Content: Search -->
                        <div class="d-flex align-center px-5 ">
                            <router-link to="/"
                                         class="d-flex align-center text-decoration-none">
                                <v-img :src="appLogo"
                                       max-height="45px"
                                       max-width="45px"
                                       alt="logo"
                                       contain
                                       eager
                                       class="app-logo me-3"></v-img>
                                <h2 class="arabic-font app-title text--primary d-none d-md-flex">
                                    {{ $t(appName) }}
                                </h2>
                            </router-link>
                        </div>

                        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
                        <div class="d-flex align-center right-row">
                            <app-bar-i18n></app-bar-i18n>
                            <v-icon v-if="$route.meta.name != 'Enrollment'">mdi-power-on</v-icon>
                            <!-- <app-bar-theme-switcher></app-bar-theme-switcher> -->
                            <!-- <img src="@/assets/images/logos/user.png"
                             alt=""
                             width="30px;"> -->
                            <div v-if="isLoggedIn">
                                <app-bar-user-menu @logout="logoutUser()"></app-bar-user-menu>
                            </div>
                        </div>
                    </div>
                </v-card>
            </template>

            <!-- Slot: Footer -->
            <template #footer>
                <div class="d-flex justify-space-between footer-width">

                    <v-card elevation="2"
                            shaped
                            tile
                            class="footer-v-card">
                        <div class="container">
                            <h1 style="color:#016fd0 !important"
                                class="px-2 footer-heading mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                     width="235"
                                     height="15"
                                     viewBox="0 0 235 15">
                                    <path fill="none"
                                          d="M0 0h235v15H0z"></path>
                                    <path d="M230.1 5.8h-3.6c-.8 0-1.3-.5-1.3-1.2s.6-1.1 1.3-1.1h6.5l1.5-3.3h-8c-3.3 0-5.2 2-5.2 4.6 0 2.8 1.8 4.4 4.7 4.4h3.6c.8 0 1.3.5 1.3 1.2s-.5 1.2-1.3 1.2h-7.8v3.3h7.8c3.3 0 5.2-1.9 5.2-4.7 0-2.8-1.7-4.4-4.7-4.4m-14.3 0h-3.6c-.8 0-1.3-.5-1.3-1.2s.6-1.1 1.3-1.1h6.5l1.5-3.3h-8c-3.3 0-5.2 2-5.2 4.6 0 2.8 1.8 4.4 4.7 4.4h3.6c.8 0 1.3.5 1.3 1.2s-.5 1.2-1.3 1.2h-7.8v3.3h7.8c3.3 0 5.2-1.9 5.2-4.7 0-2.8-1.7-4.4-4.7-4.4m-22.8 9h12.3v-3.3h-8.4V9.1h8.2V5.8h-8.2V3.5h8.4V.2H193zm-7.7-8.4h-4.1V3.5h4.1c1.2 0 1.8.7 1.8 1.5-.1.8-.7 1.4-1.8 1.4m5.7-1.5c0-2.8-2-4.7-5.2-4.7h-8.4v14.6h3.9V9.7h1.4l4.4 5.1h4.8l-4.8-5.3c2.4-.5 3.9-2.3 3.9-4.6m-21 1.8h-4.2V3.5h4.2c1.2 0 1.8.7 1.8 1.6-.1.9-.7 1.6-1.8 1.6m.4-6.5h-8.5v14.6h3.9V10h4.5c3.3 0 5.3-2.1 5.3-4.9 0-2.9-2-4.9-5.2-4.9m-9.2 0h-5l-3.8 4.4-3.8-4.4h-5.1l6.4 7.2-6.5 7.4h5l3.9-4.6 4 4.6h5.1l-6.6-7.5zm-30.7 14.6h12.3v-3.3h-8.4V9.1h8.2V5.8h-8.2V3.5h8.4V.2h-12.3zm-13.7-5.7l-6-8.9h-4.9v14.6h3.9V5.5l6.1 9.3h4.7V.2h-3.8zm-22.7-.5l2-4.9 2 4.9h-4zM93.7.2l-6.4 14.6h4.3l1.2-3h6.8l1.3 3h4.4L98.7.2h-5zM80.9 7.6v-.3c0-2.2 1.2-3.7 3.7-3.7H89V.2h-4.7C79.5.2 77 3.3 77 7.3v.3c0 4.5 2.8 7.1 7.1 7.1h1.3l1.6-3.3h-2.3c-2.3.1-3.8-1.2-3.8-3.8M71.1.2v14.6H75V.2zm-7.8 6.2h-4.1V3.5h4.1c1.2 0 1.8.7 1.8 1.5s-.6 1.4-1.8 1.4M69 4.9c0-2.8-2-4.7-5.2-4.7h-8.4v14.6h3.9V9.7h1.4l4.4 5.1H70l-4.8-5.3C67.6 9 69 7.2 69 4.9m-28.5 9.9h12.3v-3.3h-8.4V9.1h8.2V5.8h-8.2V3.5h8.4V.2H40.5zM28.1 9.4L24.8.2h-6.1v14.6h3.8V4.3l3.8 10.5h3.5l3.9-10.5v10.5h3.8V.2h-6.1zM6.9 8.6l2-4.9 2 4.9h-4zM6.4.2L0 14.8h4.3l1.2-3h6.8l1.3 3H18L11.5.2H6.4z"
                                          fill="#006fcf"></path>
                                </svg></h1>
                            <div class="px-2 mt-2"
                                 style="margin-top: 48px !important;margin-bottom: 33px !important;">
                                <a target="_blank"
                                   href="https://www.facebook.com/AmericanExpressKSA/">
                                    <img src="@/assets/images/footer/facebook.png"
                                         alt="logo"
                                         width="32px"
                                         class="mx-2"
                                         contain />
                                </a>

                                <a target="_blank"
                                   href="https://twitter.com/AmexKsa?s=20&t=v-cqRBQf0Xx9-DyLAiDhAg">
                                    <img src="@/assets/images/footer/logo.png"
                                         alt="logo"
                                         width="32px"
                                         class="mx-2"
                                         contain />
                                </a>

                                <a target="_blank"
                                   href="https://instagram.com/amexksa?utm_source=ig_profile_share&igshid=15kcwzwwopd78">
                                    <img src="@/assets/images/footer/instagram.png"
                                         alt="logo"
                                         width="32px"
                                         class="mx-2"
                                         contain />
                                </a>

                                <a target="_blank"
                                   href="https://www.linkedin.com/company/american-express-business-saudi-arabia">
                                    <img src="@/assets/images/footer/linkedin.png"
                                         alt="logo"
                                         width="32px"
                                         class="mx-2"
                                         contain />
                                </a>

                                <!-- <img src="@/assets/images/logos/youtube-logo.png"
                                 alt="logo"
                                 width="37px;"
                                 contain
                                 style="width: 37px;margin: 0px 11px 1px 11px;" /> -->
                            </div>

                            <div class="px-2 d-flex align-center flex-lg-row flex-md-column flex-sm-column flex-column"
                                 style="margin-bottom: 33px;"
                                 v-if="selectedLanguage == 'en'">
                                <li>
                                    <a target="_blank"
                                       class="text-blue"
                                       href="https://www.americanexpress.com.sa/content/terms-and-conditions"
                                       style="font-size: 13px;">
                                        {{ $t('Card Agreements') }}
                                    </a>
                                </li>

                                <v-divider style="height: 24px;"
                                           class="ml-3 mr-3"
                                           :vertical="true"></v-divider>
                                <li>
                                    <a target="_blank"
                                       class="text-blue"
                                       href="https://www.americanexpress.com.sa/content/privacy-statement"
                                       style="font-size: 13px;">
                                        {{ $t('Privacy') }}
                                    </a>
                                </li>

                                <v-divider style="height: 24px;"
                                           class="ml-3 mr-3"
                                           :vertical="true"></v-divider>
                                <li>
                                    <a target="_blank"
                                       class="text-blue"
                                       href="https://www.americanexpress.com.sa/content/rules-and-regulations"
                                       style="font-size: 13px;">
                                        {{ $t('Terms of Service') }}
                                    </a>
                                </li>
                            </div>
                            <div class="px-2 d-flex align-center flex-lg-row flex-md-column flex-sm-column flex-column"
                                 style="margin-bottom: 33px;"
                                 v-if="selectedLanguage == 'ar'">
                                <li>
                                    <a target="_blank"
                                       class="text-blue"
                                       href="https://www.americanexpress.com.sa/ar/content/%D8%A7%D9%84%D8%B4%D8%B1%D9%88%D8%B7-%D9%88%D8%A7%D9%84%D8%A7%D8%AD%D9%83%D8%A7%D9%85"
                                       style="font-size: 13px;">
                                        {{ $t('Card Agreements') }}
                                    </a>
                                </li>

                                <v-divider style="height: 24px;"
                                           class="ml-3 mr-3"
                                           :vertical="true"></v-divider>
                                <li>
                                    <a target="_blank"
                                       class="text-blue"
                                       href="https://www.americanexpress.com.sa/ar/content/%D8%A8%D9%8A%D8%A7%D9%86-%D8%B6%D9%85%D8%A7%D9%86-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9"
                                       style="font-size: 13px;">
                                        {{ $t('Privacy') }}
                                    </a>
                                </li>

                                <v-divider style="height: 24px;"
                                           class="ml-3 mr-3"
                                           :vertical="true"></v-divider>
                                <li>
                                    <a target="_blank"
                                       class="text-blue"
                                       href="https://www.americanexpress.com.sa/ar/content/%D8%A7%D9%84%D9%82%D9%88%D8%A7%D8%B9%D8%AF-%D9%88%D8%A7%D9%84%D9%82%D9%88%D8%A7%D9%86%D9%8A%D9%86"
                                       style="font-size: 13px;">
                                        {{ $t('Terms of Service') }}
                                    </a>
                                </li>
                            </div>
                            <div class="px-2"
                                 style="margin-bottom:20px">
                                <span class="text-black">{{ $t(`All users of our online services are subject to our privacy
                                    statement and agree to be bound by the terms of service.`) }}</span><br>
                                <span class="text-black"> &copy;
                                    {{ new Date().getFullYear() }}
                                    <span class="d-none d-md-inline">
                                        {{ $t(`American Express and Saudi Arabia. All Rights
                                        Reserved.`) }}
                                    </span></span>
                            </div>
                        </div>
                    </v-card>
                </div>
            </template>

            <template #v-app-content> </template>
        </layout-content-vertical-nav>

        <v-dialog v-model="dialog"
                  persistent>
            <v-card class="v-dialog-logout">
                <!-- <v-card-title>
                    {{ $t('System Timeout') }}
                </v-card-title> -->

                <v-card-text>
                    <div class="text-center">
                        <div class="secure-img">
                            <img src="@/assets/images/logos/account-detail.png"
                                 alt="">
                        </div>
                        <p class="mt-3 font-weight-bold text-lg">{{ $t('Your session is about to expire.') }}</p>
                        <p class="mt-3 text-lg">
                            {{ $t('For security reasons this session will expire in') }} 00:<span
                                  v-if="thirtySecondsTime >= 10">{{ thirtySecondsTime }}</span><span
                                  v-if="thirtySecondsTime < 10">0{{ thirtySecondsTime }}</span>
                            {{ $t('logoutAfterMsg') }}

                            {{ $t('logoutSecondMessage') }}
                        </p>
                    </div>
                </v-card-text>
                <div class="pb-5 text-center">
                    <v-btn @click="refreshToken"
                           color="primary"
                           type="submit"
                           large
                           class="">
                        {{ $t('Continue') }}
                    </v-btn>
                </div>
                <div class="pb-5 text-center">
                    <v-btn @click="logoutUser"
                           style="border: 1px solid #016FD0;"
                           type="submit"
                           large
                           class="">
                        {{ $t('Log Out') }}
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
        <div class="show-float">
            <v-btn @click="onGoHome()"
                   color="primary"
                   fixed
                   dark
                   bottom
                   right
                   fab>
                <v-icon>$vuetify.icons.home</v-icon>
            </v-btn>
        </div>

    </div>
</template>

<script>
// App Bar Components
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import { mdiHeartOutline, mdiMenu } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import themeConfig from '@themeConfig'
import { ref, watch } from '@vue/composition-api'

import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'

import sharedServices from '@/services/shared-services'

import store from '@/store';
import router from '@/router'

export default {
    components: {
        LayoutContentVerticalNav,
        // App Bar Components
        AppBarSearch,
        AppBarI18n,
        AppBarThemeSwitcher,
        AppBarUserMenu,
    },
    data: () => ({
        dialog: false,
        isLoggedIn: false,

        intervalId: null,

        inactivityDurationId: null,
        inactivityDuration: 0,

        mouseTimer: null,
        timeoutSec: 0,

        destroyedComponent: false,

        selectedLanguage: 'en',
        thirtySecondsTime: 30,
        thirtySecondsTimeID: null
    }),
    created() 
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
        if (store.get('requestKeys/accessToken'))
        {
            this.isLoggedIn = true;
            this.destroyedComponent = false;
        }
        // console.log('mounted');

        // if (this.$route.meta.layout !== 'blank')
        // {
        //     console.log(this.$route.meta.layout);
        setTimeout(() =>
        {
            this.refreshToken();
        }, 15000);
        // }
    },
    destroyed()
    {
        console.log('destroy');

        clearInterval(this.intervalId);
        this.intervalId = null;

        clearInterval(this.inactivityDurationId);
        this.inactivityDurationId = null;

        this.destroyedComponent = true;
    },
    methods: {
        onGoHome()
        {
            router.push('/home');
        },
        refreshToken()
        {
            sharedServices.refreshToken().then(resp =>
            {
                this.destroyedComponent = false;
                // console.log('refreshToken', resp)
                store.set('requestKeys/accessToken', resp.data.accessToken);
                store.set('requestKeys/refreshToken', resp.data.accessToken);
                localStorage.setItem('refreshToken', resp.data.refreshToken);

                this.checkTimeout();
                this.mouseStopped();
                this.dialog = false;

                clearInterval(this.thirtySecondsTimeID);
                this.thirtySecondsTimeID = null;
            })
        },
        mouseStopped()
        {
            // console.log("Mouse stopped");

            clearInterval(this.inactivityDurationId);
            this.inactivityDurationId = null;

            this.inactivityDuration = 0;

            this.inactivityDurationId = setInterval(() =>
            {
                this.inactivityDuration++;
                // console.log("inactivityDuration", this.inactivityDuration);
            }, 1000);
        },
        mouseMove(event)
        {
            // console.log('Mouse Moving');

            clearInterval(this.inactivityDurationId);
            this.inactivityDurationId = null;
            this.inactivityDuration = 0;

            if (!this.destroyedComponent && !this.dialog)
            {
                clearTimeout(this.mouseTimer);
                this.mouseTimer = setTimeout(() =>
                {
                    this.mouseStopped();
                }, 300);
            }
        },
        logoutUser()
        {
            clearInterval(this.intervalId);
            this.intervalId = null;

            clearInterval(this.inactivityDurationId);
            this.inactivityDurationId = null;

            router.push({ name: 'auth-login', params: {lang: this.selectedLanguage} });
        },
        checkTimeout()
        {
            console.log('Came here Check Timeout');

            this.timeoutSec = 0;
            clearInterval(this.intervalId);
            this.intervalId = null;

            this.intervalId = setInterval(() =>
            {
                this.timeoutSec++;

                // console.log('Timeout checker', this.timeoutSec);
                if (this.timeoutSec < 120 && this.thirtySecondsTime == 30 && this.inactivityDuration > 100)
                {
                    if (!this.dialog)
                    {
                        this.thirtySecondsTimeID = setInterval(() =>
                        {
                            this.thirtySecondsTime--;
                            if (this.thirtySecondsTime < 10)
                            {
                                this.thirtySecondsTime
                            }
                            if (this.thirtySecondsTime == 0)
                            {
                                clearInterval(this.thirtySecondsTimeID);
                                this.thirtySecondsTimeID = null;

                                localStorage.removeItem('authentication');
                                localStorage.removeItem('userData');
                                localStorage.removeItem('userAbility');
                                localStorage.removeItem('vuex');
                                store.set('requestKeys/accessToken', null);
                                store.set('requestKeys/enrollmentToken', null);

                                this.logoutUser();
                            }
                        }, 1000);
                        this.dialog = true;
                    }
                }

                if (this.$route.meta.layout !== 'blank' && this.timeoutSec >= 150 && !this.dialog)
                {
                    if (this.inactivityDuration <= 148)
                    {
                        clearInterval(this.intervalId);
                        this.intervalId = null;

                        clearInterval(this.inactivityDurationId);
                        this.inactivityDurationId = null;

                        this.refreshToken();
                        console.log('Came here, Refresh Call');
                    }
                    else
                    {
                        console.log('Logout Here');

                        clearInterval(this.intervalId);
                        this.intervalId = null;

                        clearInterval(this.inactivityDurationId);
                        this.inactivityDurationId = null;

                        localStorage.removeItem('authentication');
                        localStorage.removeItem('userData');
                        localStorage.removeItem('userAbility');
                        localStorage.removeItem('vuex');
                        store.set('requestKeys/accessToken', null);
                        store.set('requestKeys/enrollmentToken', null);
                        this.dialog = true;
                    }
                }
            }, 1000);
        }

    },
    setup()
    {
        const $vuetify = getVuetify()

        // Search
        const appBarSearchQuery = ref('')
        const shallShowFullSearch = ref(false)
        const maxItemsInGroup = 5
        const totalItemsInGroup = ref({
            pages: 0,
            files: 0,
            contacts: 0,
        })
        watch(appBarSearchQuery, () =>
        {
            totalItemsInGroup.value = {
                pages: 0,
                files: 0,
                contacts: 0,
            }
        })
        const searchFilterFunc = (item, queryText, itemText) =>
        {
            if (item.header || item.divider) return true

            const itemGroup = (() =>
            {
                if (item.to !== undefined) return 'pages'
                if (item.size !== undefined) return 'files'
                if (item.email !== undefined) return 'contacts'

                return null
            })()

            const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

            if (isMatched)
            {
                if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
                else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
                else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
            }

            return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
        }

        // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
        const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) =>
        {
            if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive)
            {
                toggleVerticalNavMenuActive()
            }
        }

        return {
            navMenuItems,
            handleShallShowFullSearchUpdate,

            // Search
            appBarSearchQuery,
            shallShowFullSearch,
            appBarSearchData,
            searchFilterFunc,

            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
            footerTitle: themeConfig.footer.title,
            footerLink: themeConfig.footer.link,
            icons: {
                mdiMenu,
                mdiHeartOutline,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
    .expanded-search {
        ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
            background-color: transparent;
        }

        >.d-flex>button.v-icon {
            display: none;
        }

        // ===

        &>.right-row {
            visibility: hidden;
            opacity: 0;
        }

        ::v-deep .app-bar-search-toggler {
            visibility: hidden;
        }
    }
}

.header-top-bar {
    height: 100% !important;
    width: 90vw;
    margin: 0 auto;
}

.app-title {
    font-size: 18px;
}

.footer-width {
    // width: 90vw;
    margin: 0 auto;
}

.footer-v-card {
    width: 100%;
    padding: 0px 60px;

}

.footer-heading {
    // color: #016fd0 !important;
    font-size: 20px;
}

ul {
    padding-left: 15px;
}

li {
    list-style-type: none;
}

li a {
    text-decoration: none;
    font-size: 13px;
}

.text-black {
    font-size: 13px;
}

.show-float {
    display: none;
}

.footer-container {
    width: 94.5%;
    margin: 0 auto;
}

@media screen and (max-width: 600px) {
    .footer-v-card {
        width: 100%;
        padding: 0px 0px;
    }

    .show-float {
        display: block;
    }

}
</style>
