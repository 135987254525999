import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
import createPersistedState from 'vuex-persistedstate';

// import { modules as module } from './modules';
import app from './app';
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';

import accounts from './modules/accounts';
import requestKeys from './modules/request-keys';
import enrollment from './modules/enrollment';
import card from './modules/card';

import cardStore from './modules/card-store';

import { loader } from './modules/loader';
import manageCard from './modules/manage-card';

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    plugins: [pathify.plugin, createPersistedState({ storage: window.localStorage, paths: ['accounts', 'card', 'requestKeys', 'enrollment', 'cardStore', 'manageCard'] })],
    modules: {
        appConfig: appConfigStoreModule,
        app,
        // module
        accounts,
        requestKeys,
        enrollment,
        requestKeys,
        card,
        cardStore,
        loader,
        manageCard
    },
});

export default store;