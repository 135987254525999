import { make } from 'vuex-pathify';

export const name = 'accounts';

const namespaced = true;
const state = {
  details: null
};
const getters = { ...make.getters(state) };
const actions = { ...make.actions(state) };
const mutations = { ...make.mutations(state) };

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};