import axios from 'axios';
// import config from '../../config';

// require('dotenv').config();

var baseURL = '';

// Axios init
// axios.defaults.baseURL = config.AMEX_API_URL;
// console.log('process.env.VUE_APP_AMEX_API_URL', config.AMEX_API_URL);
// axios.defaults.baseURL = 'http://192.168.134.173:1010/se/';

axios.get('config.json')
    .then((response) =>
    {
        console.log('response 1', response);
        baseURL = response.data;

        axios.defaults.baseURL = baseURL.AMEX_API_URL;
        console.log('baseURL 1', baseURL);
    })
    .catch((error) =>
    {
        axios.defaults.baseURL = 'https://online.americanexpress.com.sa/';
        console.error('Error fetching config:', error);
    });

console.log('baseURL 2', baseURL);
// http://192.168.134.173:1010/se/

// axios.interceptors.request.use(config =>
// {
//   let token = localStorage.getItem('Token');

//   // if (!!store.get('accounts/token')) config.headers.common.Authorization = 'Bearer ' + store.get('accounts/token');
//   if (token) config.headers.common.Authorization = 'Bearer ' + token;

//   // if (!!token) config.headers = { authentication: token, language: 'ar' }
//   // config.headers = { authentication: process.env.VUE_APP_API_TOKEN }
//   return config;
// });
axios.interceptors.response.use(
    response =>
    {
        return response;
    },
    error =>
    {
        // if ('401' == error.response.status) accountServices.logout();
        // console.log(error);

        return Promise.resolve({ error });
    }
);

export default axios;
