<template>
    <div>
        <div class="loader"
        v-if="visible">
             <div class="loader-img">
<img src="@/assets/images/loader-1.gif" alt="">
             </div>
           
        </div>
    </div>
</template>
       
<script>

export default {
    props: {
        visible: {
            default: false,
            type: Boolean
        }
    }
}
</script>
       
<style lang="scss" scoped>
.loader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #e6e6e678;
    // opacity: 0.4;
    z-index: 100000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.loader-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
        width: 100px;
    }
}
</style>