<template>
    <v-fade-transition mode="out-in">
        <v-icon :key="isDark"
        class="ml-3 arabic-icon"
                @click="isDark = !isDark">
            {{ isDark ? icons.day : icons.night }}
        </v-icon>
    </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import { getVuetify } from '@core/utils'

export default {
    setup()
    {
        const { isDark } = useAppConfig();
        // const sunny = $vuetify.icons.sunny;
        const $vuetify = getVuetify()
        return {
            isDark,

            // Icons
            icons: {
                night:mdiWeatherNight,
                day: '$vuetify.icons.sunny'
            },
        }
    },
}
</script>

<style></style>
