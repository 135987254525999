const apps = [
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/Home.vue'),
        meta: {
            layout: 'content',
        },
    },

    {
        path: '/active-card/:cardType',
        name: 'activeCard',
        component: () => import('@/views/card-settings/ActivateCard.vue'),
        meta: {
            layout: 'content',
        },
    },

    {
        path: '/block-card/:cardType',
        name: 'blockCard',
        component: () => import('@/views/card-settings/BlockCard.vue'),
        meta: {
            layout: 'content',
        },
    },

    {
        path: '/card-issue/:cardType',
        name: 'card-issue',
        component: () => import('@/views/card-settings/CardIssue.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/card-limit/:cardType',
        name: 'cardlimit',
        component: () => import('@/views/card-settings/CardLimit.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/card-detail',
        name: 'card-detail',
        component: () => import('@/views/card-details/CardDetail.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/manage-card-details',
        name: 'manage-card-details',
        component: () => import('@/views/card-details/ManageCard.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: () => import('@/views/view-transaction/ViewTransaction.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/pending-transactions',
        name: 'pending-transactions',
        component: () => import('@/views/view-transaction/PendingTransactions.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/view-pin/:cardType',
        name: 'view-pin',
        component: () => import('@/views/view-pin/ViewPin.vue'),
        meta: {
            layout: 'content',
        },

    },
    {
        path: '/apply-supplementary',
        name: 'applySupplementary',
        component: () => import('@/views/apply-supplementary/ApplySupplementoryForm.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/billing-dispute/:cardType',
        name: 'billingDispute',
        component: () => import('@/views/billing-dispute/ReportBilling.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: () => import('@/views/faqs/Faqs.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/views/contact-us/Contact-us.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/alert-notifications',
        name: 'alert-notifications',
        component: () => import('@/views/alert-notifications/AlertAndNotifications'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/personal-information/:cardType',
        name: 'personal-information',
        component: () => import('@/views/personal-info/PersonalDetails.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/update-password',
        name: 'UpdatePassword',
        component: () => import('@/views/update-password/UpdatePassword.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/membership-rewards',
        name: 'membership-rewards',
        component: () => import('@/views/test/MembershipRewards.vue'),
        meta: {
            layout: 'blank'
        },
    },
]


export default apps
