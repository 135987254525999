<template>
    <v-menu offset-y
            left
            nudge-bottom="14"
            min-width="230"
            content-class="user-profile-menu-content">
        <template v-slot:activator="{ on, attrs }">
            <div class="mb-1 mx-3">
                <v-icon @click="dialog = true"
                        size="19"
                        class="logout-arabic"
                        style="color: #016FD0; cursor: pointer;">
                    $vuetify.icons.logout
                </v-icon>
            </div>
            <div class="text-center">
                <v-dialog v-model="dialog">
                    <v-card class="v-dialog-logout">
                        <v-card-title>
                            <v-spacer></v-spacer>
                            <span @click="dialog = false"
                                  style="cursor: pointer;">
                                <v-icon>
                             {{icons.mdiClose}}
                                </v-icon>
                            </span>
                        </v-card-title>


                        <v-card-text>
                            <div class="text-center">
                                <div class="secure-img">
                                    <img src="@/assets/images/logos/account-detail.png"
                                         alt="">
                                </div>
                                <p class="mt-3 text-lg">{{$t('Are you sure you want to logout?')}}</p>
                            </div>
                        </v-card-text>

                        <div class="pb-5 text-center">
                            <v-btn @click="logoutUser"
                                   color="primary"
                                   type="submit"
                                   large
                                   class="">
                                {{ $t('Logout') }}
                            </v-btn>
                            <v-btn type="submit"
                                   large
                                   style="border: 1px solid #016FD0;"
                                   @click="dialog = false"
                                   class="mx-3"
                                   >
                                {{ $t('Cancel') }}
                            </v-btn>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </template>
        <v-list>
            <div class="pb-3 pt-2">
                <v-badge bottom
                         color="success"
                         overlap
                         offset-x="12"
                         offset-y="12"
                         class="ms-4"
                         dot>
                    <!-- <v-avatar size="40px"
                              color="primary"
                              class="v-avatar-light-bg primary--text">
                        <v-icon color="primary"
                                size="28">
                            {{ icons.mdiAccountOutline }}
                        </v-icon>
                    </v-avatar> -->
                </v-badge>
                <div class="d-inline-flex flex-column justify-center ms-3"
                     style="vertical-align:middle">
                    <span class="text--primary font-weight-semibold mb-n1">
                        User
                    </span>
                    <!-- <small class="text--disabled text-capitalize">{{ userData.role }}</small> -->
                </div>
            </div>

            <v-divider></v-divider>

            <!-- Profile -->
            <v-list-item :to="{ name: 'user-view', params: { id: 21 } }">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiAccountOutline }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <!-- Settings -->
            <v-list-item :to="{ name: 'page-account-settings' }">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiCogOutline }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <!-- Logout -->
            <v-list-item @click="logoutUser">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiLogoutVariant }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('Logout')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { initialAbility } from '@/plugins/acl/config'
import { useRouter } from '@core/utils'
import
{
    mdiAccountOutline,
    mdiChatOutline,
    mdiCheckboxMarkedOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiEmailOutline,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
    mdiClose
} from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'

import store from '@/store'

export default {    
    setup()
    {
        const vm = getCurrentInstance().proxy
        const { router } = useRouter()
        const userData = JSON.parse(localStorage.getItem('userData'))
        const dialog = false;

        const logoutUser = () =>
        {
            // Remove userData from localStorage
            // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem('authentication')

            // Remove userData & Ability from localStorage
            localStorage.removeItem('userData');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userAbility');
            localStorage.removeItem('vuex');

            // Reset ability
            vm.$ability.update(initialAbility)

            // Redirect to login page
            let lang = localStorage.getItem('language')
            router.push({ name: 'auth-login', params: {lang: lang} });

            store.set('requestKeys/accessToken', null);
            store.set('requestKeys/enrollmentToken', null);
            // store.set('requestKeys/requestKeys', null);
        }

        return {
            logoutUser,
            userData,
            dialog,
            icons: {
                mdiAccountOutline,
                mdiEmailOutline,
                mdiCheckboxMarkedOutline,
                mdiChatOutline,
                mdiCogOutline,
                mdiCurrencyUsd,
                mdiHelpCircleOutline,
                mdiLogoutVariant,
                mdiClose
            },
        }
    },
}
</script>

<style lang="scss">
.user-profile-menu-content {
    .v-list-item {
        min-height: 2.5rem !important;
    }
}

.secure-img {
    background: #E2F1FD;
    width: 100px;
    height: 100px;
    padding-top: 22px;
    border-radius: 79px;
    margin: 0 auto;
}
.v-dialog {
    box-shadow: none !important;
}
.v-dialog-logout {
    min-width: 250px !important;
    max-width: 500px !important;
    margin: 0 auto;
}
</style>
