import { render, staticRenderFns } from "./VerticalNavMenuGroup.vue?vue&type=template&id=2374ca6a&"
import script from "./VerticalNavMenuGroup.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenuGroup.vue?vue&type=script&lang=js&"
import style0 from "./VerticalNavMenuGroup.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VIcon,VListGroup,VListItemAction,VListItemTitle})
