import Vue from 'vue'
// import App from './App.vue'

// import * as firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/analytics";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//     apiKey: "AIzaSyDa7MPIWJpNpvEcl0SzVw_8PAcR0Ph8Rfc",
//     authDomain: "amex-sa-ltd.firebaseapp.com",
//     databaseURL: "https://amex-sa-ltd.firebaseio.com",
//     projectId: "amex-sa-ltd",
//     storageBucket: "amex-sa-ltd.appspot.com",
//     messagingSenderId: "963832326401",
//     appId: "1:963832326401:web:d2a44045735f8dff195300",
//     measurementId: "G-SK6PE1QZ15"
// };

// firebase.initializeApp(firebaseConfig);
//   firebase.analytics();

// Vue.config.productionTip = false

const firebaseConfig = {
    apiKey: "AIzaSyDa7MPIWJpNpvEcl0SzVw_8PAcR0Ph8Rfc",
    authDomain: "amex-sa-ltd.firebaseapp.com",
    databaseURL: "https://amex-sa-ltd.firebaseio.com",
    projectId: "amex-sa-ltd",
    storageBucket: "amex-sa-ltd.appspot.com",
    messagingSenderId: "963832326401",
    appId: "1:963832326401:web:d2a44045735f8dff195300",
    measurementId: "G-SK6PE1QZ15"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

// alias
// Vue.prototype.$analytics = firebase.analytics();

Vue.use(analytics);