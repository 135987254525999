import { make } from 'vuex-pathify';

export const name = 'accounts';

const namespaced = true;
const state = {
  token: null,
  status: null,
  error: null,
  user: null,
  isLoading: false,
  isAuthenticated: false,
  language: null,
  name: null,
  lastLogin: null,
  clientDetail: null
};
const getters = { ...make.getters(state) };
const actions = { ...make.actions(state) };
const mutations = { ...make.mutations(state) };

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};