import { mdiAccountGroup, mdiSailBoat } from '@mdi/js';

let role_name = localStorage.getItem('role_name');

let admin = [
  { subheader: 'MENU' },
  {
    title: 'Users', icon: mdiAccountGroup,
    children: [
      { title: 'List', to: 'user-list', },
      { title: 'Add/Update', to: { name: 'user-form', params: { id: 'add' } } }
    ],
  },
  {
    title: 'Boat Registration', icon: mdiSailBoat,
    children: [
      { title: 'List', to: 'myboat-list', },
      { title: 'Add', to: { name: 'myboat-add' } }
    ],
  },
]

let initiator = [
  { subheader: 'MENU' },
  {
    title: 'Boat Registration', icon: mdiSailBoat,
    children: [
      { title: 'List', to: 'myboat-list', },
      { title: 'Add', to: { name: 'myboat-add' } }
    ],
  },
]

let approver = [
  { subheader: 'MENU' },
  {
    title: 'Boat Registration', icon: mdiSailBoat,
    children: [
      { title: 'List', to: 'myboat-list' }
    ],
  },
]
let menus = [];

if (role_name == 'admin')
{
  menus = admin
}
else if (role_name == 'initiator')
{
  menus = initiator
}
else
{
  menus = approver
}

export default menus
