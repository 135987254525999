import { make } from 'vuex-pathify';


const namespaced = true;
const state = {
    channelId: "CONS",
    channelKey: "eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2R0NNIn0.dGuMMRxwaMlyDM1RvKfmJjQ9Y8O4CxWNM5n5n8MkkZrujz3HY5_fRw.BTrXcWL7e8Bprkoz.wSPL0p1jI0sxcvs-AU8mooMEGmkyV33-Ms4aEZtEI86bVesEnMRkIZaRFVVMYvyzOi6Slv8VbDHvJPlO2HRA2K3kU0MoWKbCiUGz82llnBurIsINU_FzUtZ7yNNj4OQrbtD5t3KeMJWf9Ghm2nn1Nhix7E3_BBegiZdJluRLUpLbNZBeCPYPcCvAGrFjRXpEUu244C4.2pPMwFprcxemIEMxkk8fxw",
    identityKey: "",
    identitySeed: "",
    requestDate: "",
    requestUID: "",
    userName: "",
    ClientCode: "",
    secretKey: "635E995196B725D9917582D85D5625CDB8DED24EEE1D96C0E3E496745A02D7CA",

    // Tokens
    accessToken: "",
    enrollmentToken: "",
    refreshToken:""
};
const getters = { ...make.getters(state) };
const actions = { ...make.actions(state) };
const mutations = { ...make.mutations(state) };

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
