import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import vMask from 'v-mask'

import mask from './plugins/mask'
import gmaps from './plugins/gmaps';
import firebase from './plugins/firebase';
// import splide from './plugins/splide'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    vuetify,
    mask,
    firebase,
    gmaps,
    //   splide,
    render: h => h(App),
}).$mount('#app')
